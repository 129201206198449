<template>
  <div class="college-contrast">
    <div class="title" @click="toggleShow">
      <i class="arrow" :class="show?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
      院校对比
      <span class="number">{{contrastDataList.length+'/20'}}</span>
    </div>
    <el-scrollbar :class="{'hide':!show}" style="height: 300px;">
      <div class="box" :class="{'hide':!show}">
        <el-checkbox-group v-model="selectedList" @change="changeCheck">
          <el-checkbox
            class="box-item"
            :label="item.ID"
            v-for="(item, index) in contrastDataList"
            :key="index"
          >
            <div class="name">
              {{ item.SchoolName }}
            </div>
            <i class="el-icon-delete delete-button" @click="deleteItem($event,item)"></i>
          </el-checkbox>
        </el-checkbox-group>
      </div>
    </el-scrollbar>
    <div v-if="show" class="submit">
      <el-button class="submit-button" @click="submitData" type="primary" size="small">开始对比</el-button>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CollegeContrast",
    data () {
      return {
        show: false,
        selectedList: [],
        localContrastList: [],
      }
    },
    computed: {
      contrastDataList () {
        let temp = this.$store.getters.contrastList
        this.selectedList = temp.map(item => {
          if (item.check) {
            return item.ID
          }
        })
        this.localContrastList = this.$store.getters.contrastList
        return this.$store.getters.contrastList
      }
    },
    mounted () {
      this.localContrastList = this.contrastDataList
    },
    methods: {
      //submit list
      submitData(){
        let temp = this.selectedList.filter(item => {
          if(item) return item
        })
        if(temp.length <= 0){
          this.$messageTips('info', '请先选择您要查看的院校！')
          return
        }
        if(temp.length > 5){
          this.$messageTips('info', '一次对比院校最多为5个！')
          return
        }
        this.$ls.set('validContrastValue', temp)
        this.$router.push('/college-compareCollege')
      },
      //adjustment check
      changeCheck (e) {
        e = e || []

        this.localContrastList.forEach(item => {
          let flag = e.includes(item.ID)
          flag?(item.check = true):(item.check = false)
        })
        this.$store.dispatch('user/setContrastList', this.localContrastList).then(_ => {
          this.$setStorage('contrastList', this.localContrastList)
        })
      },
      //toggle content show
      toggleShow () {
        this.show ? this.show = false : this.show = true
      },
      //delete item
      deleteItem (event, query) {
        event.preventDefault()
        this.localContrastList.forEach((item, index, array) => {
          if (item.ID === query.ID) {
            array.$remove(item)
          }
        })
        this.$store.dispatch('user/setContrastList', this.localContrastList).then(_ => {
          this.$setStorage('contrastList', this.localContrastList)
        })
      }
    }
  }
</script>

<style scoped lang="less">
  // @import "~@/styles/base.scss";

  .college-contrast {
    width: 200px;
    background-color: #fff;
    border: 1px solid var(--color-grey);
    position: fixed;
    top: 30%;
    right: 20px;
    z-index: 1;
    .title {
      padding: 10px 20px;
      text-align: center;
      background-color:rgba(var(--themecolor),0.75);
      color: #fff;
      position: relative;
      cursor: pointer;
      .arrow {
        font-size: 20px;
        position: absolute;
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
      }
      .number{
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        z-index: 2;
      }
    }
    .box {
      padding: 30px 10px 0;
      &-item {
        padding-bottom: 10px;
        display: flex;
        align-items: center;
        position: relative;
        margin-bottom: 20px;
        border-bottom: 1px solid var(--color-grey);
        .name {
          width: 120px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .delete-button {
          color: var(--color-red);
          position: absolute;
          left: 160px;
          top: 2px;
          z-index: 1;
        }
      }
    }
    .hide {
      display: none;
    }
    ::v-deep .el-scrollbar__wrap {
      overflow-x: hidden;
    }
    .submit{
      position: absolute;
      bottom: -32px;
      left: -1px;
      right: -1px;
      .submit-button{
        width: 100%;
      }
    }
  }

</style>
<style lang="less">
.college-contrast .el-checkbox__input.is-checked .el-checkbox__inner, .college-contrast .el-checkbox__input.is-indeterminate .el-checkbox__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.college-contrast .el-checkbox__input.is-checked + .el-checkbox__label{
  color: rgba(var(--themecolor),0.75);
}
.college-contrast .el-checkbox__input.is-checked + .el-checkbox__label:hover{
  color: rgba(var(--themecolor),1);
}
.college-contrast .el-button--primary{
  background: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.college-contrast .el-button--primary:focus, .college-contrast .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
</style>