<template>
  <div class="app-container">
    <div class="head">
      <university-head></university-head>
    </div>
    <div class="main">
      <university-main></university-main>
    </div>
  </div>
</template>

<script>
  import UniversityHead from './local-components/UniversityHead'
  import UniversityMain from './local-components/UniversityMain'

  export default {
    name: "Search-University",
    components: {
      UniversityHead,
      UniversityMain
    },
    data() {
      return {}
    },
    mounted() {

    },
    methods: {}
  }
</script>

<style scoped lang="less">
  .head,.main{
    padding-top: 20px;
    color: var(--text-font-color);
  }
</style>
