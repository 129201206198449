<template>
  <div class="university-main-container">
    <div class="statistic">筛选结果 共计{{ mainData.Total }}所大学</div>
    <ul class="content">
      <li
        class="content-item"
        @click="openUniversityDetail(item)"
        v-for="(item, index) in this.mainData.Data"
        :key="index"
      >
        <div class="content-item-left">
          <img class="content-item-left-logo" :src="item.LogoUrl" alt="" />
          <div class="content-item-left-info">
            <div class="name">{{ item.SchoolName }}</div>
            <div class="info-item">
              <span style="margin-right: 1rem">
                <span class="title">学校类别：</span>
                <span class="value">{{ item.CategoryNames }}</span>
              </span>
              <span>
                <span class="title">学历层次：</span>
                <span class="value">{{ item.EducationLevelStr }}</span>
              </span>
            </div>
            <div class="info-item">
              <span class="title">办学性质：</span>
              <div class="value">{{ item.NatureStr }}</div>
            </div>
            <div class="info-item">
              <span class="title">所在省份：</span>
              <div class="value">{{ item.ProvinceName }}</div>
            </div>
          </div>
        </div>
        <div class="content-item-right">
          <template v-if="ruleFormLs">
            <div>
              <div class="bodyTitleBox">
                <span>{{ ruleFormLs.Year + "年 " }}</span>
                <span>{{ ruleFormLs.ProvinceIdName }}</span>
                <span v-if="ruleFormLs.SubjectType">{{
                  ruleFormLs.SubjectType == 1 ? "文科" : "理科"
                }}</span>
                <span v-if="ruleFormLs.SelectSubject">{{
                  ruleFormLs.SelectSubject
                }}</span>
                <span> 录取情况 </span>
              </div>
              <div class="bodyTagText">
                <el-tag v-if="item.ScoreLow"
                  >最低分<span>{{ item.ScoreLow }}</span></el-tag
                >
                <el-tag v-if="item.RankLow"
                  >最低位次<span>{{ item.RankLow }}</span></el-tag
                >
                <el-tag v-if="item.BatchName">{{ item.BatchName }}</el-tag>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="content-item-right-info">
              <i class="el-icon-location-information custom-icon-color"></i>
              <span>学校地址：</span><span>{{ item.Address }}</span>
            </div>
            <div class="content-item-right-info">
              <i class="el-icon-phone-outline custom-icon-color"></i>
              <span>联系电话：</span><span>{{ item.Tel }}</span>
            </div>
          </template>
          <div class="tags">
            <span class="I985 i-item" v-if="item.TagNames.search('985') !== -1"
              >985</span
            >
            <span class="I211 i-item" v-if="item.TagNames.search('211') !== -1"
              >211</span
            >
          </div>
        </div>
        <div class="right-button" @click="addContrast($event, item)">
          {{ contrastIncludes(item) ? "取消对比" : "+ 对比" }}
        </div>
      </li>
    </ul>
    <custom-pagination
      :pageIndex="query.PageIndex"
      :pageSize="query.PageSize"
      :total="mainData.Total"
      @handleCurrentChange="turnPage"
    ></custom-pagination>
    <college-contrast></college-contrast>
  </div>
</template>

<script>
import API from '@/api/config'
// import { SchoolList, SchoolListBySchoolName } from "@/api/search_university";
import CollegeContrast from "./CollegeContrast";
export default {
  components: {
    CollegeContrast,
  },
  name: "UniversityMain",
  data() {
    return {
      mainData: {},
      searchSchoolByNameMark: false,
      query: {
        PageIndex: 1,
        PageSize: 10,
      },
      contrastList: this.$store.getters.contrastList,
      ruleFormLs:null
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    //add item to contrast
    addContrast(event, row) {
      event.stopPropagation();
      this.$getStorage('contrastList').then(res => {
        this.contrastList = res
        let temp = this.contrastList.find((item, index, array) => {
          if (item.ID === row.ID) {
            return true;
          }
        });
        if (temp) {
          this.contrastList.$remove(temp);
        } else {
          if (this.contrastList.length >= 20) {
            this.$messageTips("info", "对比院校最多添加20个！");
            return;
          }
          this.contrastList.push({
            SchoolName: row.SchoolName,
            ID: row.ID,
            check: true,
          });
        }
        this.$store
          .dispatch("user/setContrastList", this.contrastList)
          .then((_) => {
            this.$setStorage("contrastList", this.contrastList);
          });
      }).catch(_ => {
        this.contrastList.push({
          SchoolName: row.SchoolName,
          ID: row.ID,
          check: true,
        });
        this.$store
          .dispatch("user/setContrastList", this.contrastList)
          .then((_) => {
            this.$setStorage("contrastList", this.contrastList);
          });
      })
    },
    //进入院校详情
    openUniversityDetail(item) {
      let routerUrl = this.$router.resolve({
        path: "/university-detail",
        query: {
          id: item.ID,
        },
      });
      window.open(routerUrl.href);
    },
    //翻页
    turnPage(currentPage) {
      this.query.PageIndex = currentPage;
      if (this.searchSchoolByNameMark) {
        this.getSchoolByName();
      } else {
        this.getSchoolList();
      }
    },
    //筛选学校
    screenSchoolList() {
      this.$globalEventBus.$on("screenParameters", (query) => {
        this.searchSchoolByNameMark = false;
        this.query.PageIndex = 1;
        Object.assign(this.query, query);
        this.$delete(this.query, "SchoolName");

        
        this.query.CategoryId = this.query.CategoryId || null
        this.query.EducationLevel = this.query.EducationLevel || null
        this.query.ProvinceId = this.query.ProvinceId || null
        this.query.SchoolNature = this.query.SchoolNature || null
        this.query.Tag = this.query.Tag || null


        this.getSchoolList().then(() => {
          this.ruleFormLs = this.$ls.get("ruleFormLs");
        })
        this.$globalEventBus.$emit("screenButtonLoading", false);
      });
    },
    //通过名字查找学校
    searchSchoolByName() {
      this.$globalEventBus.$on("searchSchoolByNameMark", (SchoolName) => {
        this.searchSchoolByNameMark = true;
        this.query = {
          PageIndex: 1,
          PageSize: 10,
          SchoolName: SchoolName,
        };
        this.getSchoolByName().then(() => {
          this.$globalEventBus.$emit("searchButtonLoading", false);
        });
      });
    },
    //初始化数据
    initialize() {
      this.getSchoolList();
      this.screenSchoolList();
      this.searchSchoolByName();
    },
    //获取学校列表数据
    async getSchoolList() {
      this.mainData = (await API.School.SchoolList(this.query)).data;
      this.mainData.Total = Number(this.mainData.Total)
    },
    //通过名字获取学校列表
    async getSchoolByName() {
      this.mainData = (await API.School.SchoolListBySchoolName(this.query)).data;
      this.mainData.Total = Number(this.mainData.Total)
    },

    contrastIncludes(row) {
      let flag = this.contrastList.findIndex((item) => item.ID === row.ID);
      if (flag === -1) {
        return false;
      } else {
        return true;
      }
    },
  },
};
</script>

<style scoped lang="less">
.university-main-container {
  .statistic {
    padding: 0 2rem 2rem;
    font-weight: 700;
    color: var(--text-font-color);
  }
  .content {
    padding: 0;
    &-item {
      margin-bottom: 1.5rem;
      padding: 2rem;
      background-color: #fff;
      border-radius: 0.2rem;
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: box-shadow 0.2s linear;
      position: relative;
      &:hover {
        box-shadow: 0 1px 10px 2px var(--boxShadow);
      }
      &-left {
        display: flex;
        align-items: center;
        &-logo {
          width: 6rem;
          height: 6rem;
        }
        &-info {
          width: 30rem;
          margin-left: 6rem;
          .name {
            color: rgb(var(--themecolor));
            font-size: var(--text-font-size);
            font-weight: 700;
          }
          .info-item {
            display: flex;
            align-items: center;
            margin-top: 0.5rem;
            .title {
              font-size: var(--text-font-size);
              font-weight: 700;
              color: var(--text-font-color);
            }
            .value {
              font-size: var(--text-font-size);
              color: var(--text-font-color);
            }
          }
        }
      }
      &-right {
        box-sizing: border-box;

        width: 23rem;
        padding: 2rem;
        background-color: rgba(var(--themecolor), 0.15);
        position: relative;
        &-info {
          font-size: var(--text-font-size);
          color: var(--text-font-color);
          .custom-icon-color {
            color: rgba(var(--themecolor),0.75);
            margin-right: 0.3rem;
          }
          &:first-child {
            margin-bottom: 1rem;
          }
        }
        .tags {
          position: absolute;
          top: -0.3rem;
          right: 0.5rem;
          .i-item {
            padding: 0.5rem 0.5rem 1rem;
            border-bottom-left-radius: 50%;
            border-bottom-right-radius: 50%;
            color: #fff;
          }
          .I985 {
            margin-right: 0.3rem;
            background: linear-gradient(to bottom, rgba(226, 187, 78, 1), #ff7016);
          }
          .I211 {
            background: linear-gradient(to bottom, rgba(var(--themecolor), 0.6), rgba(var(--themecolor), 1));
          }
        }
      }
      .right-button {
        box-sizing: border-box;

        width: 100px;
        padding: 5px 10px;
        text-align: center;
        border: 1px solid rgba(var(--themecolor),0.75);
        border-radius: 5px;
        color: rgba(var(--themecolor),0.75);
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
      .right-button:hover{
        border: 1px solid rgba(var(--themecolor),1);
        color: rgba(var(--themecolor),1);
      }
    }
  }
}
.bodyTitleBox {
  span {
    margin-right: 10px;
  }
}
.bodyTagText{
  margin-top: 10px;
}
</style>
<style lang="less">
.university-main-container .el-pagination.is-background .el-pager li:not(.disabled).active{
  background-color: rgba(var(--themecolor),0.75);
}
.university-main-container .el-pagination.is-background .el-pager li:not(.disabled):hover{
  color: rgba(var(--themecolor),0.75);
  background-color: rgba(var(--themecolor),0.2);
}
.university-main-container .el-pagination.is-background .el-pager li:not(.disabled).active:hover{
  color: #FFF;
  background-color: rgba(var(--themecolor),0.75);
}
.university-main-container .el-pagination.is-background .btn-next:hover, .university-main-container .el-pagination.is-background .btn-prev:hover, .university-main-container .el-pagination.is-background .el-pager li:hover{
  background-color: rgba(var(--themecolor),0.2);
}
</style>