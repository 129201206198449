<template>
  <div class="university-head-container">
    <div class="top">
      <div class="top-left">
        <el-autocomplete
          class="top-left-input"
          v-model="query.SchoolName"
          placeholder="请输入院校"
          value-key="SchoolName"
          :fetch-suggestions="searchResultList"
          @select="handleSelect"
        ></el-autocomplete>
        <el-button class="top-left-button" @click="showSearchSchoolList" type="primary" :loading="searchButtonLoading">{{searchButtonLoading?'搜索中...':'搜索'}}</el-button>
      </div>
      <div class="top-right">
        <span class="top-right-title">热门大学:</span>
        <ul class="top-right-box">
          <li class="top-right-box-item" @click="openUniversityDetail(item.ID)" v-for="(item, index) in hotCollege" :key="index">{{item.name}}</li>
        </ul>
      </div>
    </div>
    <div class="bottom">
      <condition-screen sign="search-university"></condition-screen>
    </div>
  </div>
</template>

<script>
  import API from '@/api/config'
  import ConditionScreen from '@/views/custom-components/ConditionScreen'

  export default {
    name: "UniversityHead",
    components: {
      ConditionScreen,
    },
    data () {
      return {
        schoolName:'',
        searchButtonLoading: false,
        //popular college
        hotCollege: [
          {name: '清华大学', ID: "114ba307-ecce-416a-ac72-14daed0f00bd"},
          {name: '北京大学', ID: "3a360552-9016-4978-862c-57d1d2b84837"},
          {name: '复旦大学', ID: "bc63f2a2-7341-45eb-b90e-c965688bf99b"}
        ],
        query:{
          SchoolName: '',
          PageIndex: 1,
          PageSize: 999999
        }
      }
    },
    mounted () {
      this.$globalEventBus.$on('searchButtonLoading',data=>{
        this.searchButtonLoading = data
      })
    },
    methods: {
      //打开院校详情
      openUniversityDetail(ID){
        let routerUrl = this.$router.resolve({
          path: '/university-detail',
          query: {
            id: ID
          }
        })
        window.open(routerUrl.href)
      },
      //展示搜索院校
      showSearchSchoolList(){
        if(this.query.SchoolName.trim()){
          this.searchButtonLoading = true
          this.$globalEventBus.$emit('searchSchoolByNameMark',this.query.SchoolName)
        }
      },
      //选择搜索的结果
      handleSelect(selectSchool){
        let routerUrl = this.$router.resolve({
          path: '/university-detail',
          query:{
            id: selectSchool.ID
          }
        })
        window.open(routerUrl.href)
      },
      //搜索大学
      async searchResultList (schoolName, callback) {
        if(!schoolName.trim()) return
        this.getSchoolList().then(res =>{
          callback(res)
        })
      },
      //获取学校列表
      async getSchoolList(){
        return (await API.School.SchoolListBySchoolName(this.query)).data.Data
      }
    }
  }
</script>

<style scoped lang="less">
  .university-head-container {
    .top {
      display: flex;
      justify-content: space-between;
      &-left {
        width: 45%;
        min-width: 28rem;
        display: flex;
        &-input {
          width: 80%;
        }
        &-button {
          width: 20%;
          background: rgba(var(--themecolor),0.75);
          border-color: rgba(var(--themecolor),0.75);
          margin-left: 10px;
        }
      }
      &-right {
        width: 40%;
        min-width: 28rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &-title {
          color: var(--text-font-color);
          font-size: var(--text-font-size);
        }
        &-box {
          width: 60%;
          display: flex;
          justify-content: space-between;
          color: rgb(var(--themecolor));
          font-size: var(--text-font-size);
          &-item {
            cursor: pointer;
          }
        }
      }
    }
    .bottom {
      margin-top: 2rem;
    }
  }
</style>
<style lang="less">
.university-head-container .el-radio-button__orig-radio:checked + .el-radio-button__inner{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
  box-shadow: -1px 0 0 0 rgba(var(--themecolor),0.75);
}
.university-head-container .el-radio-button__orig-radio:checked + .el-radio-button__inner:hover{
  color: #fff;
}
.screen-container .content-item .el-radio-button .el-radio-button__inner:hover{
  border: 1px solid rgba(var(--themecolor),0.75);
}
.university-head-container .el-radio-button__inner:hover{
  color: rgb(var(--themecolor));
}
.university-head-container .el-input__inner:focus,.university-head-container .el-select .el-input.is-focus .el-input__inner{
  border-color: rgba(var(--themecolor),0.75);
}
.university-head-container .el-button--primary,.university-head-container .el-button--primary.is-plain{
  background-color: rgba(var(--themecolor),0.75);
  border-color: rgba(var(--themecolor),0.75);
}
.university-head-container .el-button--primary:hover{
  background: rgba(var(--themecolor),1);
  border-color: rgba(var(--themecolor),1);
}
.university-head-container .el-button--primary.is-plain{
  color: #fff;
}
.university-head-container .el-input-number__decrease:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled), .el-input-number__increase:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled){
  border-color: rgba(var(--themecolor),0.75);
}
.university-head-container .el-input-number__decrease:hover, .el-input-number__increase:hover{
  color: rgba(var(--themecolor),0.75);
}


.el-select-dropdown__item.selected{
  color: rgb(var(--themecolor));
}
.el-year-table td.today .cell{
  color: rgba(var(--themecolor),1);
}
.el-year-table td .cell:hover, .el-year-table td.current:not(.disabled) .cell{
  color: rgba(var(--themecolor),0.75);
}
.el-date-picker__header-label.active, .el-date-picker__header-label:hover{
  color: rgba(var(--themecolor),0.75);
}
.el-picker-panel__icon-btn:hover{
  color: rgba(var(--themecolor),0.75);
}
</style>